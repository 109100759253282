import { BREAKPOINTS, COLORS } from "igualigual-ds";
import styled from "styled-components";

export const HeaderContainer = styled.div`
position: fixed;
z-index: 1000;

width: 100%;
background-color:${COLORS.transparent};

display: flex;
justify-content: space-between;
align-items:center;

height:56px;
padding: 0 24px;
;

@media screen and (${BREAKPOINTS.mobile.media}) {
  height:44px;
  }
`

export const NavBarContainer = styled.div`
display: flex;
place-items: center;
gap: 20px;
padding: 8px 0;
`