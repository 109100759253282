'use client'

import Link from 'next/link'
import { styled } from 'styled-components'
import { SHADOWS, variables } from 'igualigual-ds'

export const StyledPreviewBox = styled.div`
  position: fixed;
  top: 4rem;
  left: 50%;

  padding: 12px 22px;

  background: ${variables.COLORS.neutral00};
  border: 1px solid ${variables.COLORS.primary190};
  box-shadow: ${SHADOWS.md};
  border-radius: 50px;

  transform: translateX(-50%);
  z-index: 10000;
`

const PreviewBox = () => {
  return (
    <StyledPreviewBox>
      This page is a preview.{' '}
      <Link
        href="/api/preview-exit"
        prefetch={false}
        className="color-primary190"
      >
        Exit preview mode
      </Link>
    </StyledPreviewBox>
  )
}
export default PreviewBox
