import Image from 'next/image';
import Link from 'next/link';
import { useGlobalContext } from '@/lib/globalContext';



const Logo = () => {
  const state = useGlobalContext();

  return (
      <Link
        href={`/${state.lang !== state?.i18n?.defaultLocale ? state.lang : ''}`}
      >
        <Image
          src={'/assets/logo.svg'}
          alt={process.env.NEXT_PUBLIC_SITE_NAME as string}
          width={122}
          height={28}
          priority={true}
        />
      </Link>
  );
};
export default Logo;
