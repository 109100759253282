'use client'
import { INavBar } from '../Header/Header.interface'
import {
  Button,
  Dropdown,
  DropdownItem,
  Flex,
  Heading,
  types
} from 'igualigual-ds'
import { useState } from 'react'
import Link from 'next/link'
import {
  IconMenuMobile,
  MenuItems,
  MenuMobileContainer,
  Overlay
} from './MenuMobile.theme'
import Logo from '../Logo'

const MenuMobile = ({ menus, editions }: INavBar) => {
  const mobileMenu = menus['mobile-menu']
  const editionsMenu = editions.items

  const [isOpen, setIsOpen] = useState(false)

  return (
    <MenuMobileContainer>
      <IconMenuMobile
        icon="icon-menu"
        onClick={() => setIsOpen(prev => !prev)}
      />

      <Overlay isOpen={isOpen}>
        <Flex
          justify="space-between"
          align="center"
          className="w-100"
          style={{ height: '44px' }}
        >
          <Logo />
          <IconMenuMobile
            icon="icon-close"
            onClick={() => setIsOpen(prev => !prev)}
          />
        </Flex>

        <MenuItems>
          {mobileMenu &&
            mobileMenu.map(menu => (
              <Link href={menu.url} key={menu.id}>
                <Heading size="h2">
                  <span
                    onClick={() => {
                      setIsOpen(prev => !prev)
                    }}
                  >
                    {menu.title.toUpperCase()}
                  </span>
                </Heading>
              </Link>
            ))}

          {editionsMenu && (
            <Dropdown
              hasIcon={true}
              title={<Heading size="h2">EDIÇÕES ANTERIORES</Heading>}
            >
              {editionsMenu.map(menu => (
                <DropdownItem key={menu.id}>
                  <Link href={menu.slug} key={menu.id}>
                    <span onClick={() => setIsOpen(prev => !prev)}>
                      {menu.title}
                    </span>
                  </Link>
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </MenuItems>

        <Link href={'/passatempo'} className="w-100">
          <Button
            size="medium"
            variant="primary"
            onClick={() => setIsOpen(prev => !prev)}
            className="w-100"
          >
            PASSATEMPO
          </Button>
        </Link>
      </Overlay>
    </MenuMobileContainer>
  )
}

export default MenuMobile
