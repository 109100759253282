import { Button, Dropdown, DropdownItem, Heading, types } from 'igualigual-ds'
import Link from 'next/link'
import { INavBar } from '../Header/Header.interface'
import { NavBarContainer } from '../Header/Header.theme'
import styled from 'styled-components'

export const NavBar = ({ menus, editions }: INavBar) => {
  const editionsMenu = editions.items
  const primaryMenu = menus['primary-menu']

  return (
    <NavBarContainer>
      {primaryMenu &&
        primaryMenu.map(menu => (
          <Link
            href={menu.url}
            target={menu.target}
            prefetch={true}
            key={menu.id}
          >
            <Button
              key={menu.id}
              size={types.SIZE.small}
              variant={types.VARIANT.link}
            >
              {menu.title}
            </Button>
          </Link>
        ))}

      {editionsMenu.length > 0 && (
        <IconPequeno>
          <Dropdown
            hasIcon={true}
            title={
              <Button size={types.SIZE.small} variant={types.VARIANT.link}>
                Edições Anteriores
              </Button>
            }
          >
            {editionsMenu.map(menu => (
              <DropdownItem key={menu.id}>
                <Link href={menu.slug} key={menu.id}>
                  <Heading>{menu.title}</Heading>
                </Link>
              </DropdownItem>
            ))}
          </Dropdown>
        </IconPequeno>
      )}

      <Link href={'/passatempo'}>
        <Button size={types.SIZE.small} variant={types.VARIANT.primary}>
          PASSATEMPO
        </Button>
      </Link>
    </NavBarContainer>
  )
}

export default NavBar

const IconPequeno = styled.ul`
  i {
    font-size: 16px;
  }
`
