'use client';

import React, { Suspense } from 'react';
import { BlocksProps } from '@/lib/wordpress/_types/blocksStyle';
import displayBlock from '@/lib/wordpress/blocks/displayBlock';


const Footer = ({ footer }: { footer: { blocks: BlocksProps[] } }) => {


  return (
    <>
      <footer id='site-footer' className='bg-primary130 color-secondary230'>
        <Suspense>
          {!!footer?.blocks?.length &&
            footer?.blocks?.map((block: BlocksProps, index: number) => {
              return displayBlock(block, index);
            })}
        </Suspense>
      </footer>
    </>
  );
};

export default Footer;
