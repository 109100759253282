'use client'

import { useGlobalContext } from '@/lib/globalContext'
import { HeaderContainer } from './Header.theme'

import { Row, useMediaMatch, COLORS } from 'igualigual-ds'
import Logo from '../Logo'
import MenuMobile from '../MenuMobile'
import gsap from 'gsap'
import { useRef } from 'react'
import { useGSAP } from '@gsap/react'
import NavBar from '../NavBar/NavBar'

const Header = () => {
  const isMobile = useMediaMatch()
  const state = useGlobalContext()
  const headerRef = useRef(null)

  useGSAP(() => {
    gsap.to(headerRef.current, {
      backgroundColor: COLORS.primary100,
      duration: 0.3,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: headerRef.current,
        start: 'top top',
        end: 'bottom top',
        scrub: 1
      }
    })
  })

  const menus = state?.menus
  const editions = state?.editions

  return (
    <HeaderContainer id="masthead" ref={headerRef}>
      <Row wrap="nowrap" align="center" justify="space-between">
        <Logo />
        {!isMobile && <NavBar menus={menus} editions={editions} />}
        {isMobile && <MenuMobile menus={menus} editions={editions} />}
      </Row>
    </HeaderContainer>
  )
}

export default Header
