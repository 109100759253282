import { COLORS, Icon } from 'igualigual-ds'
import styled, { css } from 'styled-components'

export const MenuMobileContainer = styled.div`
  color: ${COLORS.white};

`
interface OverlayProps {
  isOpen: boolean
}
export const Overlay = styled.div<OverlayProps>`
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${COLORS.primary100};
  height: 100dvh;
  width: 100dvw;

  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 24px 40px 24px;
  color: ${COLORS.white};

  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.4s ease, transform 0.4s ease;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}

  color: ${COLORS.white};
  
`

export const MenuItems = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
`

export const IconMenuMobile = styled(Icon)`
  color: ${COLORS.white};
  cursor: pointer;
  position: relative;

  transition: transform 0.3s ease, color 0.3s ease;

  &:hover {
    transform: scale(1.1);

    &::before {
      transform: scale(1.1);
    }
  }


  &::before {
    content: '';
    transition: transform 0.3s ease;
  }
`;