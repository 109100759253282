import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"Anton\",\"arguments\":[{\"variable\":\"--font-Anton\",\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"anton\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"Manrope\",\"arguments\":[{\"variable\":\"--font-Manrope\",\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/PreviewBox/PreviewBox.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/WebVitals/WebVitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "/app/src/lib/globalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/registry.tsx");
