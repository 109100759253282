import { createGlobalStyle } from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS, variables } from 'igualigual-ds'

const CustomStyles = createGlobalStyle`
  body {
    font-family: var(--font-anton);
    overflow: unset !important;
    font-weight: 400;
    
    h1, h2, h3, h4 {
      font-family: var(--font-anton);
    }
    p{
      font-family: var(--font-manrope);
      line-height: 1.5;
      @media screen and (${BREAKPOINTS.mobile.media}) {
        line-height: 1.4;
        }
    }

    input, textarea{
      font-family: var(--font-anton);
    }
    
    button{
      font-family: var(--font-anton);
    }
  }  

  .font-anton{
    font-family: var(--font-anton);
  }

  #site-footer{    
    & a{
      transition: all 0.3s linear;
      &:hover{
        color: ${variables.COLORS.primary190} !important;
      }
    }
  }


  //structure
  body > main{
    overflow: clip;
  }

  .style-dark{
    color: ${COLORS.white};
    background-color:${COLORS.secondary230};
  }
  .style-light{
    color: ${COLORS.white};
    background-color: ${COLORS.primary100};
  }

  .page-loading{
    cursor: wait !important;

    & body {
      opacity: 0.6 !important;
      pointer-events: none !important;
    }
  }


  //animations
  .elements_animated {
    position: relative;
    opacity: 0;
    pointer-events: none;

    will-change: transform, opacity;

    &.top,
    &.top-t-bottom {
      transform: translateY(-80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.bottom,
    &.bottom-t-top {
      transform: translateY(80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.left,
    &.left-t-right {
      transform: translateX(-80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }
    &.right,
    &.right-t-left {
      transform: translateX(80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }

    &.zoom-in {
      transform: scale(0.8);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.zoom-out {
      transform: scale(1.2);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.masked {
      opacity: 0;
      transition-delay: 1s;
      transition: opacity 0.175s ease;
    }


    &.in-viewport {
      opacity: 1;
    }

    transition: all 0.4s ease-in-out;
  }
  
  .elements_animated_mask {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary130);
    transform-origin: 100% 0;
    z-index: 15;
    transition: transform 1s ease-in-out;

    .in-viewport & {
      transform: scaleX(0);
    }
  }

  //Scrollbars
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px ${COLORS.primary100};
    background-color:${COLORS.primary100};
  }
  
  ::-webkit-scrollbar {
    width: 7px;
    height: 2px;
    background-color: ${COLORS.success};
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primary130};
  }
    

  //Smooth scroll
  html.lenis {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped,
  .lenis.no-scroll {
    overflow: hidden;
  }

  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }

  
  .w-sm-50 {
    @media screen and (${BREAKPOINTS.mobile.media}) {
      width: 50%
    }
  }


  .saude-quote-sticker{
    position: absolute !important;
    bottom: -60%;
    right: -18%;
    transform: scale(.7);
    @media screen and (${BREAKPOINTS.mobile.media}) {
      right: -17%;
      bottom: -70%;
      scale: 0.5;
    }
  }

  .saude-report-quote{
    bottom: 0;
    left: 13%;
    transform: translate(2%, -25%);
    @media screen and (${BREAKPOINTS.mobile.media}) {
      position: relative !important;
      bottom: unset;
      left: unset;
      transform: unset;
    }
}
.gradient-spacing-sticker{
    bottom: 20%;
    right: -15%;
    position: absolute !important;
    @media screen and (${BREAKPOINTS.mobile.media}) {
      bottom: -10%;
        right: -20%;
        scale: .6;
    }
}

.sexims-sticker{
  top: -5%;
  right: 10%;
  position: absolute !important;
  z-index: 20;
    @media screen and (${BREAKPOINTS.mobile.media}) {
      top: -7%;
      right: -10%;
      scale: .6;
    }
}
.sexism-quote-holder{
  position: absolute !important;
  bottom: 0;
  left: 0;
  z-index: 30;
  transform: translate(-50%, 15%);
  max-width: 470px;
    @media screen and (${BREAKPOINTS.mobile.media}) {
      position: unset !important;
      bottom: unset;
      left: unset;
      transform: unset;
      z-index: unset;
      max-width: 100%;
  }
}


.quote-top-left {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 30;
  transform: translate(-20%, -50%);
  max-width: 540px;

@media screen and (${BREAKPOINTS.mobile.media}) {
    position: unset !important;
    bottom: unset;
    left: unset;
    transform: unset;
    z-index: unset;
    max-width: 100%;
}
}



.quote-bottom-right {
  position: absolute !important;
    bottom: 0;
    right: 0;
    z-index: 30;
    transform: translate(50%, -25%);
    max-width: 540px;
  
    @media screen and (${BREAKPOINTS.mobile.media}) {
    position: unset !important;
  bottom: unset;
left: unset;
transform: unset;
z-index: unset;
max-width: 100%;
}
}



.full-width{
  [class*="sticker-"] { 
    width: fit-content !important;
 }
  :first-child{
    width:100%;
    /* @media screen and (${BREAKPOINTS.mobile.media}) { 
      padding: 40px 
    } */
  }
}


.sticker-top-in-left {
  position: absolute !important;
  z-index: 30;
  top: 0%;
  left: 0%;
  transform: translate(-100%, -65%);


  @media screen and (${BREAKPOINTS.mobile.media}) {
        position: absolute !important;
        z-index: 30;
        top: 0%;
        left: 0%;
        transform: scale(.5) translate(0%, -80%);
    }
}


.sticker-bottom-in-left {
  position: absolute !important;
  z-index: 31;
  top: 0%;
  left: 0%;
  transform: translate(-125%, -20%);


  @media screen and (${BREAKPOINTS.mobile.media}) {
      position: absolute !important;
      z-index: 31;
      top: 0%;
      left: 0%;
      transform: scale(.5) translate(-30%, -20%);
  }
}


.sticker-top-in-right {
  position: absolute !important;
  z-index: 31;
  top: 0%;
  right: 0%;
  transform: translate(90%, -58%);

  @media screen and (${BREAKPOINTS.mobile.media}) {
    top: 0%;
    right: 0%;
    transform: scale(.5) translate(30%, -50%);
  }
}

.sticker-bottom-in-right {
  position: absolute !important;
  z-index: 32;
  top: 0%;
  right: 0%;
  transform: translate(70%, 125%);


  @media screen and (${BREAKPOINTS.mobile.media}) {
    z-index: 32;
    top: 0%;
    right: 0%;
    transform: scale(0.5) translate(40%, 120%);
  }
}

.alternative-font{
 font-family: ${FONTS.alternativeFont}
}

.rotate-15deg-inverted{
  transform: rotate(-15deg);

  @media screen and (${BREAKPOINTS.mobile.media}) {
    padding: 80px;
  }
}

.content-intro {
  height: 90dvh;
  display: flex;
  flex-direction: column;
  width: 100%;
}


@media screen and (${BREAKPOINTS.mobile.media}) {
  .grid-mobile {
    
    > :first-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      

      > :nth-child(1) {
        grid-row: 2;
      }

      > :nth-child(2) {
        grid-column: 2;
      }

      > :nth-child(3) {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }
}
  
.word-breaker{
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 35;
  transform: rotate(-15deg) translate(-15%, 20%);
  width: max-content;


  @media screen and (${BREAKPOINTS.mobile.media}) {
    top: 50%;
    left: 50%;
    transform: rotate(-15deg) translate(-43%, -95%);
  }
}



.cm-logo{
  @media screen and (${BREAKPOINTS.mobile.media}) {
    width: 162px;
  }
}
.footer-links{
  display: flex;
  gap: 16px;
  justify-content:flex-end;
  @media screen and (${BREAKPOINTS.mobile.media}) {
    justify-content:flex-start;
  }
}
.mobile-margin {
  @media screen and (${BREAKPOINTS.mobile.media}) {
  margin: 0 8px;
  }
}


.arch-absolute{
  position: absolute !important;
  width: 400px;
  top: 0;
  right: 0;
  transform: translate(0%, -20%);
  @media screen and (${BREAKPOINTS.mobile.media}) {
    display: none;
  }
}

.seta-baixo {
  width: 70px;
  position: absolute !important;
  bottom: 5%;
  left: 40%;
  z-index: 3;
  @media screen and (${BREAKPOINTS.mobile.media}) {
   display: none;
  }
}
.segue-nos-black {
 display: flex;
 align-items: center;
 gap: 20px;
li{
  color: ${COLORS.neutral90}
}

}


.sqn-line{  
  width: 200px;
  bottom: -19px;
  left: 30%;
  z-index: 3;
  position: absolute !important;
  @media screen and (${BREAKPOINTS.mobile.media}) {
    display: none;
  }

}

.embaixador-line{
  width: 260px;
    bottom: -16px;
    left: 25%;
    z-index: 3;
  position: absolute !important;


  @media screen and (min-width: 1500px) {
    width: 325px;
        bottom: -16px;
        left: 29%;
  }

  @media screen and (${BREAKPOINTS.mobile.media}) {
    width: 170px;
    bottom: -7px;
    left: 51%;
  }

}

` as any

export default CustomStyles
